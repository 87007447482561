import axios from "axios";
import Vue from "vue";

export default "comApplicant"

// 지원자관련 공통
Vue.prototype.comApplicant = {
    /** 현재 모집년도/모집학기/모집차수 조회 */
    async selectRecruitInfo(thiss) {
        thiss.logger.debug(thiss, "selectRecruitInfo()");

        await axios
            .post("/applicant/applicant/selectRecruitInfo.do", {})
            .then((response) => {
                let tempObj = response.data.recruitInfoList[0];
                for(let [key, val] of Object.entries(tempObj)){
                    if(key == 'firstRegistProgramId' || key == 'lastUpdateProgramId'){
                        continue;
                    }
                    thiss.searchForm[key] = val;
                }
                thiss.searchForm['recruitYear'] = tempObj['nowRecruitYear'];
                thiss.searchForm['termCd'] = tempObj['nowTermCd'];
            })
            .catch((error) => {
                thiss.exceptionHandler(thiss, error);
            });
    },
    /** 모집년도 목록 조회 */
    selectYearList(thiss) {
        thiss.logger.debug(thiss, "selectRecruitYearList()");
        axios
            .post("/manage/recruitordno/selectRecruitYearList.do", {})
            .then((response) => {
                thiss.yearList = response.data.recruitYearList;
            })
            .catch((error) => {
                thiss.exceptionHandler(thiss, error);
            });
    },
    /** 모집학기 목록 조회 */
    selectTermList(thiss) {
        thiss.logger.debug(thiss, "selectTermList()");

        axios
            .post("/system/code/selectList.do", {classCd : 'TERM_CD'})
            .then((response) => {
                thiss.termList = response.data.codeList;
                thiss.termList.reduce((obj, data) => {
                    obj[data.comCd] = data.cdNm;
                    return obj;
                }, thiss.termObj);
            })
            .catch((error) => {
                thiss.exceptionHandler(thiss, error);
            });
    },
    /** 모집학기 목록 조회 */
    selectRecruitOrdNoList(thiss) {
        thiss.logger.debug(thiss, "selectRecruitOrdNoList()");
        thiss.recruitOrdNoList = [];
        thiss.searchForm.nowRecruitOrdNo = "1";

        axios
            .post("/manage/recruitordno/selectRecruitOrdNoList.do", thiss.searchForm)
            .then((response) => {
                thiss.recruitOrdNoList = response.data.recruitOrdNoList;
            })
            .catch((error) => {
                thiss.exceptionHandler(thiss, error);
            });
    },
    /** 모집캠퍼스 조회 */
    selectCampusList(thiss) {
        thiss.logger.debug(thiss, "selectCampusList()");
        axios
            .post("/applicant/applicant/selectCampusCd1.do", {})
            .then((response) => {
                thiss.campusList = response.data;
                thiss.campusList.reduce((obj, data) => {
                    obj[data.campusCd] = data.campusNm;
                    return obj;
                }, thiss.campusObj);
            })
            .catch((error) => {
                thiss.exceptionHandler(thiss, error);
            });
    },
    /** 전체 모집캠퍼스 조회 */
    selectCampusList2(thiss) {
        thiss.logger.debug(thiss, "selectCampusList2()");
        axios
            .post("/applicant/applicant/selectCampusCd2.do", {})
            .then((response) => {
                thiss.campusList = response.data;
                thiss.campusList.reduce((obj, data) => {
                    obj[data.campusCd] = data.campusNm;
                    return obj;
                }, thiss.campusObj);
            })
            .catch((error) => {
                thiss.exceptionHandler(thiss, error);
            });
    },
    /** 모집전공 조회 */
    selectMajorList(thiss) {
        thiss.logger.debug(thiss, "selectMajorList()");
        thiss.majorList = [];
        thiss.searchForm.majorCd = "";

        if(!thiss.searchForm.campusCd) {
            return;
        }

        axios
            .post("/applicant/applicant/selectMajorCd.do", thiss.searchForm)
            .then((response) => {
                thiss.majorList = response.data;
                thiss.majorList.reduce((obj, data) => {
                    obj[data.majorCd] = data.majorNm;
                    return obj;
                }, thiss.majorObj);
            })
            .catch((error) => {
                thiss.exceptionHandler(thiss, error);
            });
    },
    /** 기존 지원내역 있으면 정보 세션에 저장 */
    async insertSupportInfo(thiss) {
        await axios
            .post("/applicant/selectScreenList.do", { userId: sessionStorage.getItem("userId") })
            .then((response) => {
                let screenList = response.data.screenList;

                if (screenList.some(o => o.supportNo !== ""))
                {
                    let filteredList = screenList.filter(o => o.supportNo !== "");

                    sessionStorage.setItem("supportNo", filteredList[0].supportNo);
                    sessionStorage.setItem("screenInfo", JSON.stringify(filteredList[0]));
                }
            })
            .catch((error) => {
                thiss.exceptionHandler(thiss, error);
            });

    },
};
