import Vue from "vue";
import axios from "axios";
import Stomp from "webstomp-client";
import SockJS from "sockjs-client";

export default "auth";

// const env = process.env.NODE_ENV
//Config
Vue.prototype.wsurl = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/ws";
Vue.prototype.stompClient;

Vue.prototype.userInfo = (key) => {
    return sessionStorage.getItem(key);
};

Vue.prototype.auth = {
    login: async (userId, password, returnUrl, normalId = "") => {

        // FormData 인스턴스 생성
        const formData = new FormData();

        // 필요한 데이터를 formData에 추가
        formData.append('username', userId);
        formData.append('password', password);

        if(!Vue.prototype.utils.isEmpty(normalId))
        {
            formData.append('normalId', normalId);
        }

        return await axios
            .post("/common/auth/login.do", formData, {
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                console.log(response)
                window.open(returnUrl, '_self')
            })
            .catch((error) => {
                console.log(error);
                if (error.response != null && error.response.data != null && error.response.data.message != null) {
                    alert(error.response.data.message)
                }
                return false;
            });
    },
    setReturnUrl : (returnUrl) => {
        if (returnUrl == "/user/login" || returnUrl === undefined)
        {
            returnUrl = "/";
        }

        return returnUrl;
    },
    info: async () => {
        return await axios
            .post("/common/auth/info.do")
            .then((response) => {
                let result = response.data;
                if (result["userId"] != "") {
                    Object.keys(result).forEach((key) => {
                        // console.log(key + ":" + result[key]);
                        sessionStorage.setItem(key, result[key]);
                    });
                }

                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    logout: async () => {
        return await axios
            .post("/common/auth/logout.do")
            .then((response) => {

                if (response.status == 200) {
                    sessionStorage.clear();
                    window.open("/", "_self");
                    return true;
                } else {
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
    },
    connect: () => {
        //console.log(sessionStorage);
        if (sessionStorage.getItem("userId") == null || sessionStorage.getItem("sessionId") == null) {
            return;
        }
        // console.log("connectSocket()");
        const serverURL = Vue.prototype.wsurl;
        let socket = new SockJS(serverURL);
        Vue.prototype.stompClient = Stomp.over(socket);
        // 소켓 로그 안나오도록 조치
        Vue.prototype.stompClient.debug = () => {
        };
        // console.log(`소켓 연결을 시도합니다. 서버 주소: ${serverURL}`);
        Vue.prototype.stompClient.connect({},

            (frame) => {
                console.log("소켓 연결 성공", frame);
                Vue.prototype.stompClient.subscribe("/ws/send" + sessionStorage.getItem("sessionId") + sessionStorage.getItem("browserAccessId"), (res) => {
                    let message = JSON.parse(res.body);
                    console.log(message)
                    if(message.command == "isValidUser")
                    {
                        if(message.remainTime > 0)
                        {
                            sessionStorage.setItem("remainTime", message.remainTime);
                        }
                        else{
                            Vue.prototype.auth.logout().then(() => {
                                window.open("/user/login?expire=true", "_self");
                            });
                        }
                    }
                });
            },
            (error) => {
                console.log(error);
            }
        );
    },
    isValidUser: () =>
    {
        if (Vue.prototype.stompClient && Vue.prototype.stompClient.connected)
        {
            const msg = {
                userId: sessionStorage.getItem("userId"),
                sessionId: sessionStorage.getItem("sessionId"),
                browserAccessId: sessionStorage.getItem("browserAccessId"),
                command: "isValidUser"
            };

            Vue.prototype.stompClient.send("/ws/receive", JSON.stringify(msg), {});
        }
    }
};