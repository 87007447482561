import Vue from "vue";

export default "logger";

//LogLevel
const env = process.env.NODE_ENV;
const logLevel = env === "development" ? "debug" : env === "info" ? "debug" : env === "production" ? "error" : "info";

//Logger
Vue.prototype.logger = {
    debug: function(obj, args) {
        if (logLevel == "debug") {
            var exportName = "";
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log(`[` + exportName + `]`, args);
        }
    },
    warn: function(obj, args) {
        if (logLevel == "debug" || logLevel == "warn") {
            var exportName = "";
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log(`[` + exportName + `]`, args);
        }
    },
    error: function(obj, args) {
        if (logLevel == "debug" || logLevel == "warn" || logLevel == "error") {
            var exportName = "";
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log(`[` + exportName + `]`, args);
        }
    },
};

let errorDisplay = false;
//AXIOS ExceptionHandler
Vue.prototype.exceptionHandler = function(obj, errors) {
    Vue.prototype.logger.debug(obj, errors);

    // 기존
    // if (!Vue.prototype.utils.isEmpty(errors.response))
    // {
    //     if (!Vue.prototype.utils.isEmpty(errors.response.status))
    //     {
    //         let statusCode = errors.response.status;
    //         let errorData = Vue.prototype.utils.isEmpty(errors.response.data.error) ? errors.response.status : errors.response.data.error;
    //         let message = Vue.prototype.utils.isEmpty(errors.response.data.message) ? errors.response.statusText : errors.response.data.message;
    //
    //         alert("[" + statusCode + " " + errorData + "]\n" + message);
    //     }
    //     else{
    //         console.log(errors.response);
    //     }
    // }
    // else{
    //     console.log(errors);
    // }

    if (!Vue.prototype.utils.isEmpty(errors.response))
    {
        if (!Vue.prototype.utils.isEmpty(errors.response.status))
        {
            if(!errorDisplay)
            {
                if (errors.response.status === 403)
                {
                    alert("You do not have permission to access this page.")
                    errorDisplay = true;
                    window.open("/", "_self");
                }
                else
                {
                    let statusCode = errors.response.status;
                    let errorData = Vue.prototype.utils.isEmpty(errors.response.data.error) ? errors.response.status : errors.response.data.error;
                    let message = Vue.prototype.utils.isEmpty(errors.response.data.message) ? errors.response.statusText : errors.response.data.message;

                    alert("[" + statusCode + " " + errorData + "]\n" + message);
                }
            }
        }
        else
        {
            console.log(errors.response);
        }
    }
    else
    {
        console.log(errors);
    }
};