<template>
  <div class="spinner" v-if="loading"> 
  </div>
</template>

<script>
export default {
  props: {
    loading: {                              
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style>
.spinner{
  background: url(@/assets/image/spinner_icon.svg) 0 0 no-repeat;
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index:99999;
  animation: spinner 1.5s linear infinite;
}
@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>